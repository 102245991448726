import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import { appWithTranslation } from 'next-i18next';
import { utils } from '@jobdone/jobdone-web-design-system';
import { AnimatePresence } from 'framer-motion';
import Amplify, { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { useApollo } from '../../lib/apolloClient';
import awsConfig from '../../aws-config';
import 'react-toastify/dist/ReactToastify.css';

Amplify.configure(awsConfig);
Auth.configure(awsConfig);

const { ThemeProvider } = utils;

function MyApp({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ThemeProvider>
      <AnimatePresence exitBeforeEnter>
        <ApolloProvider client={apolloClient}>
          <Component {...pageProps} />
          <ToastContainer
            position='bottom-left'
            pauseOnHover
          />
        </ApolloProvider>
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default appWithTranslation(MyApp);
