import { ApolloLink } from '@apollo/client';
import { asyncMap } from '@apollo/client/utilities';
import { i18n } from 'next-i18next';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import Router from 'next/router';
import { toast } from 'react-toastify';
import { serverErrorEnum } from '@jobdone/jobdone-shared-library';
import getAccessToken from './awsGetAccessToken';
import { signOut } from './userActions';

export const interceptRequest = setContext(async (_, context) => {
  const token = await getAccessToken();
  const orgId = typeof window !== 'undefined' ? localStorage.getItem('organizationId') : null;
  return {
    ...context,
    headers: {
      ...context.headers,
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...(orgId ? { Organization: orgId } : {}),
    },
  };
});

export const interceptResponse = new ApolloLink((operation, forward) => asyncMap(forward(operation), (response) => response));

export const catchErrors = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions }) => {
      if (extensions) {
        switch (extensions.errorType) {
          case serverErrorEnum.UNAUTHORIZED:
            toast.error(
              i18n?.t(`validations:server.${serverErrorEnum.UNAUTHORIZED}`),
            );
            break;
          case serverErrorEnum.FORBIDDEN:
            toast.error(
              i18n?.t(`validations:server.${serverErrorEnum.FORBIDDEN}`),
            );
            break;
          case serverErrorEnum.UNAUTHENTICATED:
            toast.error(
              i18n?.t(`validations:server.${serverErrorEnum.UNAUTHENTICATED}`),
            );
            signOut()
              .then(() => Router.push(`/login?redirect=${Router.asPath}`))
              .catch((error: Error) => toast.error(error.message));
            break;
          case serverErrorEnum.TIMEOUT_ERROR:
            toast.error(
              i18n?.t(`validations:server.${serverErrorEnum.TIMEOUT_ERROR}`),
            );
            break;
          case serverErrorEnum.INTERNAL_SERVER_ERROR:
            toast.error(
              i18n?.t(`validations:server.${serverErrorEnum.INTERNAL_SERVER_ERROR}`, { time: (extensions as any).dateTime.split('T').join(' ').split('Z')[0] }),
            );
            break;
          case serverErrorEnum.NOT_FOUND:
          case serverErrorEnum.BAD_USER_INPUT:
            toast.error(i18n?.t(`validations:exceptions.${message}`));
            break;
          default:
            toast.error(message);
            break;
        }
      }
      console.log(`[GraphQL error]: Message: ${message}`);
    });
  }

  console.log(`[Network error]: ${String(networkError)}`);
});
